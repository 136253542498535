<template>
    <default-template
        title="温熱・省エネ設備機器ポータルサイト(住宅版)"
        :is-visible-public="isOrganization"
        :is-visible-logout="!isPublic"
        :is-visible-pass-update="!isPublic"
        :is-visible-jigyosya-login="isPublic"
        :is-house="true"
        :is-visible-breadcrumb="true"
        :breadcrumb-category="'ハイブリッド給湯機一覧'"
        :breadcrumbItem="packageName"
        :is-visible-user-header="isOrganization"
        :is-visible-administrator-header="isAdministrator"
    >
        <!-- データ取得中は非表示にする -->
        <div v-if="!isInProgress" class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">
                    ハイブリッド給湯機
                    <span v-if="isDetail">
                        詳細
                    </span>
                    <span v-if="isDelete">
                        削除
                    </span>
                    <span v-if="isEnabled">
                        承認
                    </span>
                </h2>
            </div>
            <div
                v-if="isPublic"
                key="isPublic"    
            >
                選択されたパッケージ：
                <span
                    ref="jest-hybrid-water-heaters-detail-package-name"
                >
                    {{ packageName }}
                </span>
                <app-button
                    name="コピー"
                    @click="copy"
                    ref="jest-hybrid-water-heaters-detail-copy-button"
                    class="inline outlined wd_100px uq_btnCopy"
                />
            </div>
            <div
                v-if="isDelete"
                key="isDelete"
            >
                <p
                    v-if="isSoftDelete"
                    class="red_bold"
                >
                    削除処理後、以下全てが共通する機器は登録できなくなります。<br>
                    本当に削除しますか？<br>
                    ブランド事業者名：{{brandName}}<br>
                    ヒートポンプユニット番号（ヒートポンプ・貯湯一体型ユニット含む）：{{hybridWaterHeatersDetail.pu_number}}<br>
                    貯湯ユニット品番（貯湯・補助熱源一体型ユニット含む）：{{hybridWaterHeatersDetail.tu_number}}<br>
                    補助熱源機品番:{{hybridWaterHeatersDetail.bb_number}}<br>
                    成績証明書番号または自己適合宣言書番号（試験品質）:{{hybridWaterHeatersDetail.certificate_number}}<br>
                </p>
                <p v-else>以下の機器を削除します。よろしいですか？</p>
            </div>
            <div
                v-if="isEnabled"
                key="isEnabled"
            >
                <p>以下の機器を承認します。よろしいですか？</p>
            </div>
            <div class="uq_width_fitter">
                <table>
                    <!-- ラベル部分 -->
                    <tr>
                        <th colspan="3">
                            項目名
                        </th>
                        <th>
                            データ
                        </th>
                    </tr>
                    <!-- 表の中身 -->
                    <device-table-row
                        :name="'ブランド事業者名'"
                        :value="brandName"
                        :name_colspan="3"
                        ref="jest-hybrid-water-heaters-detail-brand-name"
                    />
                    <device-table-row
                        :name="'ヒートポンプユニット製造事業者名'"
                        :value="hybridWaterHeatersDetail.pu_manufacturer_name"
                        :name_colspan="3"
                        ref="jest-hybrid-water-heaters-detail-pu-name"
                    />
                    <device-table-row
                        :name="'ヒートポンプユニット番号（ヒートポンプ・貯湯一体型ユニット含む）'"
                        :value="hybridWaterHeatersDetail.pu_number"
                        :name_colspan="3"
                        ref="jest-hybrid-water-heaters-detail-pu-number"
                    />
                    <device-table-row
                        :name="'貯湯ユニット製造事業者名'"
                        :value="hybridWaterHeatersDetail.tu_manufacturer_name"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'貯湯ユニット品番（貯湯・補助熱源一体型ユニット含む）'"
                        :value="hybridWaterHeatersDetail.tu_number"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'補助熱源機製造事業者名'"
                        :value="hybridWaterHeatersDetail.bb_manufacturer_name"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'補助熱源機品番'"
                        :value="hybridWaterHeatersDetail.bb_number"
                        :name_colspan="3"
                    />
                    <device-table-row
                        v-if="!isPublic"
                        :name="'情報公開年月日'"
                        :value="date(hybridWaterHeatersDetail.publish_date)"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'発売予定年月日'"
                        :value="date(hybridWaterHeatersDetail.release_date)"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'性能確認区分'"
                        :value="hybridWaterHeatersSystemCertificates.performance_validation_category"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'成績証明書番号または自己適合宣言書番号（試験品質）'"
                        :value="hybridWaterHeatersDetail.certificate_number"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'電気ヒートポンプの加熱量を求める式の係数'"
                        :type="'式（8）の係数'"
                        :algebra="'1'"
                        :value="hybridWaterHeatersSystemCertificates.param_1"
                        :name_rowspan="2"
                        :type_rowspan="2"
                    />
                    <device-table-row
                        :algebra="'2'"
                        :value="hybridWaterHeatersSystemCertificates.param_2"
                    />
                    <device-table-row
                        :name="'タンクユニットの消費電力量を求める式の係数'"
                        :type="'式（3）の係数'"
                        :algebra="'3'"
                        :value="hybridWaterHeatersSystemCertificates.param_3"
                        :name_rowspan="2"
                        :type_rowspan="2"
                    />
                    <device-table-row
                        :algebra="'4'"
                        :value="hybridWaterHeatersSystemCertificates.param_4"
                    />
                    <device-table-row
                        :name="'電気ヒートポンプの基準熱効率'"
                        :type="'外気温度 -7℃'"
                        :algebra="'5'"
                        :value="hybridWaterHeatersSystemCertificates.param_5"
                        :name_rowspan="4"
                    />
                    <device-table-row
                        :type="'外気温度 2℃'"
                        :algebra="'6'"
                        :value="hybridWaterHeatersSystemCertificates.param_6"
                    />
                    <device-table-row
                        :type="'外気温度 7℃'"
                        :algebra="'7'"
                        :value="hybridWaterHeatersSystemCertificates.param_7"
                    />
                    <device-table-row
                        :type="'外気温度 25℃'"
                        :algebra="'8'"
                        :value="hybridWaterHeatersSystemCertificates.param_8"
                    />
                    <device-table-row
                        :name="'電気ヒートポンプの最大加熱量 MJ/日'"
                        :algebra="'9'"
                        :value="hybridWaterHeatersSystemCertificates.param_9"
                        :name_colspan="2"
                    />
                    <device-table-row
                        :name="'タンクユニットの年平均熱損失率'"
                        :algebra="'10'"
                        :value="hybridWaterHeatersSystemCertificates.param_10"
                        :name_colspan="2"
                    />
                    <device-table-row
                        :name="'電気ヒートポンプの運転下限外気温度 ℃'"
                        :algebra="'11'"
                        :value="hybridWaterHeatersSystemCertificates.param_11"
                        :name_colspan="2"
                    />
                    <device-table-row
                        :name="'バックアップボイラーの熱効率'"
                        :type="'給湯モード熱効率'"
                        :algebra="'12'"
                        :value="hybridWaterHeatersSystemCertificates.param_12"
                    />
                    <device-table-row
                        :name="'ヒートポンプ昼間沸上率'"
                        :algebra="'13'"
                        :value="hybridWaterHeatersSystemCertificates.param_13"
                        :name_colspan="2"
                    />
                    <device-table-row
                        :name="'測定データ判定プログラム'"
                        :value="hybridWaterHeatersSystemCertificates.determination_program"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'パラメータ抽出プログラム'"
                        :value="hybridWaterHeatersSystemCertificates.sampling_program"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'試験基準バージョン'"
                        :value="hybridWaterHeatersSystemCertificates.standard_version"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'備考'"
                        :value="hybridWaterHeatersDetail.note"
                        :name_colspan="3"
                    />
                </table>
                <div class="ly_buttonBox">
                    <app-button
                        name="戻る"
                        @click="back"
                        ref="jest-hybrid-water-heaters-detail-back-button"
                        class="cancel"
                    />
                    <app-button
                        v-if="isShowCertificate"
                        name="成績証明書又は自己適合宣言書を確認"
                        @click="getFile"
                        ref="jest-hybrid-water-heaters-detail-pdf-button"
                    />
                    <app-button
                        v-if="isChangeablePublishDate"
                        name="情報公開日等の変更"
                        @click="updatePublishDate"
                        ref="jest-hybrid-water-heaters-detail-change-publish-date"
                    />
                    <app-button
                        v-if="isEnabled"
                        name="承認"
                        @click="approveEnableDevice"
                        ref="jest-hybrid-water-heaters-detail-enable-button"
                    />
                    <app-button
                        name="申請"
                        @click="toPublish"
                        v-if="isAllowedToPublish"
                        ref="jest-hybrid-water-heaters-detail-to-publish"
                    />
                    <app-button
                        name="承認"
                        v-if="isAllowedToApprove"
                        @click="toApprove"
                        ref="jest-hybrid-water-heaters-detail-to-approve"
                    />
                    <div
                        v-show="showDisableButtons"
                        class="uq_foldedContainer">
                        <app-button
                            v-if="isDelete"
                            name="削除"
                            class="delete"
                            @click="deleteDevice"
                            ref="jest-hybrid-water-heaters-detail-delete-button"
                        />
                        <app-button
                            name="削除"
                            @click="toDelete"
                            class="delete"
                            v-if="isAllowedToDelete"
                            ref="jest-hybrid-water-heaters-detail-to-delete"
                        />
                        <app-button
                            name="取下"
                            @click="toCancel"
                            class="delete"
                            v-if="isAllowedToCancel"
                            ref="jest-hybrid-water-heaters-detail-to-cancel"
                        />
                        <app-button
                            name="取下申請"
                            v-if="isAllowedToPublish"
                            @click="toPublishAndCancel"
                            class="delete"
                            ref="jest-hybrid-water-heaters-detail-publish-cancel"
                        />
                    </div>
                    <app-button
                        v-if="isDelete || isAllowedToDelete || isAllowedToCancel||isAllowedToPublish"
                        name="…"
                        @click="toggle"
                        ref="foo"
                        class="uq_togglebutton textonly"
                    />
                </div>
            </div>
            
            <!-- PDFダウンロード用に実在する要素が必要 -->
            <div id="invisible"></div>
        </div>
    </default-template>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { move, isSet, isSetNumber, isSetString } from '@/util'
import moment from 'moment'
import DeviceTableRow from '@/components/molecules/DeviceTableRow.vue'
import pdf from "@/mixins/pdf"
import Progress from '@/mixins/progress.js'
/**
 * P017 ハイブリッド給湯器詳細（一般）
 * P154 ハイブリッド給湯器詳細（事業者）
 * 
 * [算出プロパティ]
 * packageName ... 表示している機器の"ブランド事業者名、ヒートポンプユニット番号、貯湯ユニット品番、補助熱源機品番、成績証明書番号"
 * date ... 文字列の年月日をYYYY/MM/DDの形に成形する
 * isPublic...publicのルートか否かを判定(表示と遷移に使用)
 * isOrganization...事業者ページか否か
 * isChangeablePublishDate...公開ページでなく、情報公開日が来ていない機器であることを判定（情報公開日の変更可否の判定）
 * 
 * [イベント処理]
 * copy ... 表示している機器のブランド事業者名、ヒートポンプユニット番号、貯湯ユニット品番、補助熱源機品番、成績証明書番号のテキストをクリップボードにコピーする
 * back ... 表示中のページに対応した戻り先へ遷移する（一般、事業者、管理者）
 * getFile ... 当該機器データに紐づく成績証明書等PDFを別ウィンドウで表示する
 */
export default {
    name:'HybridWaterHeatersDetail',
    components: {
        DeviceTableRow
    },
    data(){
        return {
            showDisableButtons:false,
        }
    },
    mixins:[pdf, Progress],
    async mounted(){
        const loader = this.$loading.show()
        this.startProgress()
        // 機器詳細データを取得する
        await this.getDeviceDetail({id:this.$route.params.id, isPublic:this.isPublic})
        // データを取得できなかった場合はNot Foundを表示
        if (Object.keys(this.hybridWaterHeatersDetail).length == 0) {
            move(this.$router, '/NotFound')
        } else {
            await this.getHybridWaterHeatersCertificate(this.hybridWaterHeatersDetail.hybrid_water_heater_certificate_id)
        }
        this.finishProgress()
        loader.hide()
    },
    computed:{
        ...mapGetters({
            hybridWaterHeatersDetail:'hybridWaterHeaters/hybridWaterHeatersDetail',
            hybridWaterHeatersSystemCertificates:'hybridWaterHeaters/hybridWaterHeatersSystemCertificates',
            organization:'auth/organization',
        }),
        packageName(){
            // 取得したデータを元に設定する
            // return "ブランド事業者名 ヒートポンプユニット番号 貯湯ユニット品番 補助熱源機品番 成績証明書番号"
            return this.brandName + ' '
                 + isSetString(this.hybridWaterHeatersDetail.pu_number) + ' '
                 + isSetString(this.hybridWaterHeatersDetail.tu_number) + ' '
                 + isSetString(this.hybridWaterHeatersDetail.bb_number) + ' '
                 + isSetString(this.hybridWaterHeatersDetail.certificate_number)
        },
        packageNameForCopy(){
            // 取得したデータを元に設定する
            // return "ブランド事業者名 ヒートポンプユニット番号 貯湯ユニット品番 補助熱源機品番 成績証明書番号"
            return this.brandName
                 + isSetNumber(this.hybridWaterHeatersDetail.pu_number)
                 + isSetNumber(this.hybridWaterHeatersDetail.tu_number)
                 + isSetNumber(this.hybridWaterHeatersDetail.bb_number)
                 + isSetNumber(this.hybridWaterHeatersDetail.certificate_number)
        },
        /**
         * 文字列の年月日をYYYY年MM月DD日の形に成形する
         */
        date(){
            return function(dateString) {
                return moment(dateString).format('YYYY年MM月DD日')
            }
        },
        /**
         * publicのルートか否かを判定(表示と遷移に使用)
         */
        isPublic(){
            return this.$route.meta.noGuard ?? false
        },
        /**
         * 事業者ページか否か
         */
        isOrganization() {
            return this.$route.meta.routeType === 'organization'
        },
        /**
         * 管理者ページか否か
         */
        isAdministrator() {
            return this.$route.meta.routeType === 'administrator'
        },
        /**
         * 公開ページでなく、情報公開日が来ていない機器であることを判定
         * （情報公開日の変更可否の判定）
         */
        isChangeablePublishDate(){
            return this.isOrganization
        },
        /**
         * 詳細画面か否かの判定
         */
        isDetail() {
            return this.$route.meta.type === 'detail'
        },
        /**
         * 削除画面か否かの判定
         */
        isDelete() {
            return this.$route.meta.type === 'delete'
        },
        /**
         * 承認画面か否かの判定
         */
        isEnabled() {
            return this.$route.meta.type === 'approvedEnabled'
        },
        /**
         * 成績証明書の表示可否判定
         */
        isShowCertificate(){
            return (this.isDetail || this.isEnabled) && this.cannotShowCertificate === false
        },
        /**
         * ステータスによる表示不可判定
         * （一般ページかつ取下げ公開中の場合true）
         */
        cannotShowCertificate(){
            return this.isPublic && this.hybridWaterHeatersDetail.status === 5
        },
        /**
         * 機器の申請・取下申請の可否を返す（事業者のみ）
         * （機器が公開申請前かつ機器を表示している事業者がブランド事業者である場合true）
         */
        isAllowedToPublish(){
            return this.isDetail && this.isOrganization && this.hybridWaterHeatersDetail.status === 1 && this.isBrandUser
        },
        /**
         * 機器の削除の可否を返す
         * （事業者としての削除条件または管理者としての削除条件を満たしていればtrue）
         */
        isAllowedToDelete(){
            return this.isDetail && (this.isOrganizationAllowedToDelete ||this.isAdministratorAllowedToDelete)
        },
        /**
         * 機器の削除の可否を返す（事業者）
         * （事業者の場合、機器が公開申請前または「機器が公開申請済みかつ機器を表示している事業者がブランド事業者である」場合true）
         */
        isOrganizationAllowedToDelete(){
            return this.isOrganization && (this.hybridWaterHeatersDetail.status === 1 || (this.hybridWaterHeatersDetail.status === 2 && this.isBrandUser))
        },
        /**
         * 機器の削除の可否を返す（管理者）
         * （管理者の場合、公開申請済、公開承認済、一般公開中、取下公開中のいずれかのステータスの場合true）
         */
        isAdministratorAllowedToDelete(){
            const statusAbleToDelete = [2, 3, 4, 5]
            return this.isAdministrator && statusAbleToDelete.includes(this.hybridWaterHeatersDetail.status)
        },
        /**
         * 機器の取下の可否を返す（事業者のみ）
         * （ステータスが公開申請済・公開承認済・一般公開中のいずれかかつ機器を表示している事業者がブランド事業者であり、
         * さらにまだ取下げフラグがfalseである場合はtrue）
         */
        isAllowedToCancel(){
            const statusAbleToCancel = [2, 3, 4]
            return this.isDetail && this.isOrganization && statusAbleToCancel.includes(this.hybridWaterHeatersDetail.status)
                && this.isBrandUser && this.hybridWaterHeatersDetail.canceled === false
        },
        /**
         * 機器の承認の可否を返す（管理者のみ）
         * （ステータスが公開申請済である場合true）
         */
        isAllowedToApprove(){
            return this.isDetail && this.isAdministrator && this.hybridWaterHeatersDetail.status === 2
        },
        /**
         * 機器のブランド事業者IDがログイン中の事業者IDと一致するか否か
         */
        isBrandUser() {
            return this.hybridWaterHeatersDetail.brand_name.organization_id == this.organization.id
        },
        brandName(){
            return isSet(this.hybridWaterHeatersDetail.brand_name) ? this.hybridWaterHeatersDetail.brand_name.name : null
        },
        isSoftDelete(){
            const statusForSoftDelete = [3, 4, 5]
            return this.isAdministrator && this.isDelete && statusForSoftDelete.includes(this.hybridWaterHeatersDetail.status)
        }
    },
    methods:{
        ...mapActions({
            getDeviceDetail:'hybridWaterHeaters/getDeviceDetail',
            getHybridWaterHeatersCertificate:'hybridWaterHeaters/getHybridWaterHeatersCertificate',
            getDeviceCertificateFile:'hybridWaterHeaters/getDeviceCertificateFile',
            deleteHybridWaterHeaters:'hybridWaterHeaters/deleteDevice',
            approveEnableHybridWaterHeaters:'hybridWaterHeaters/approveEnableDevice',
            approveDisableHybridWaterHeaters:'hybridWaterHeaters/approveDisableDevice',
            clearError:'error/clear',
            updateError:'error/update',
        }),
        /*
         * 表示している機器の"ブランド事業者名 ヒートポンプユニット番号 貯湯ユニット品番 補助熱源機品番 成績証明書番号"のテキストをクリップボードにコピーする
         */
        copy(){
            this.$copyText(this.packageNameForCopy)
            alert("コピーしました。")
        },
        /**
         * 表示中のページに対応した戻り先へ遷移する（一般、事業者、管理者）
         */
        back(){
            if (this.isPublic) {
                move(this.$router, '/hybrid_water_heaters')
            } else if (this.isOrganization) {
                move(this.$router, '/organization/hybrid_water_heaters')
            } else if (this.isAdministrator) {
                move(this.$router, '/admin/hybrid_water_heaters')
            }
        },
        /**
         * 情報公開日等の変更ページに遷移
         */
        updatePublishDate(){
            move(this.$router, '/organization/hybrid_water_heaters/' + this.hybridWaterHeatersDetail.id + '/update/publish_date')
        },
        /**
         * 当該機器データに紐づく成績証明書等PDFを別ウィンドウで表示する
         */
        async getFile(){
            const file = await this.getDeviceCertificateFile(this.hybridWaterHeatersDetail.hybrid_water_heater_certificate_id)
            this.openPdf(file, 'Certification')
        },
        /**
         * ハイブリッド給湯機の削除実行
         */
        deleteDevice() {
            this.clearError()
            return this.deleteHybridWaterHeaters()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        },
        approveEnableDevice() {
            this.clearError()
            return this.approveEnableHybridWaterHeaters()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        },
        approveDisableDevice() {
            this.clearError()
            return this.approveDisableHybridWaterHeaters()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        },
        /**
         * 機器の削除画面に遷移
         */
        toDelete(){
            this.clearError()
            let url
            if (this.isOrganization) {
                url = '/organization/hybrid_water_heaters/' + this.hybridWaterHeatersDetail.id + '/delete'
            } else if (this.isAdministrator) {
                url = '/admin/hybrid_water_heaters/' + this.hybridWaterHeatersDetail.id + '/delete'
            }
            move(this.$router, url)
        },
        /**
         * 機器の公開申請画面に遷移（事業者のみ）
         */
        toPublish(){
            this.clearError()
            move(this.$router, '/organization/hybrid_water_heaters/' + this.hybridWaterHeatersDetail.id + '/status/published')
        },
        /**
         * 機器の取下画面に遷移（事業者のみ）
         */
        toCancel(){
            this.clearError()
            move(this.$router, '/organization/hybrid_water_heaters/' + this.hybridWaterHeatersDetail.id + '/status/canceled')
        },
        /**
         * 機器の取下申請画面に遷移（事業者のみ）
         */
        toPublishAndCancel(){
            this.clearError()
            move(this.$router, '/organization/hybrid_water_heaters/' + this.hybridWaterHeatersDetail.id + '/status/published/cancel')
        },
        /**
         * 機器の承認画面に遷移（管理者のみ）
         */
        toApprove(){
            this.clearError()
            move(this.$router, '/admin/hybrid_water_heaters/' + this.hybridWaterHeatersDetail.id + '/approved/enabled')
        },
        /**
         * 削除・取り下げのボタンを…ボタンで表示切替
         */
        toggle(){
            this.showDisableButtons = !this.showDisableButtons
        },
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    max-width: 800px;
}
.uq_width_fitter{
    width: 100%;
}
.uq_width_fitter>table{
    width: calc(100% - 16px);
    min-width: 800px;
}
.bl_underHeader >>> button.el_button.uq_btnCopy{
    width: 100px;
    min-width: 100px;
    height: 24px;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.25em 1em;
    font-weight: bolder;
    border-radius: 13px;
    background-color: white;
}
.bl_underHeader >>> button.el_button.uq_btnCopy:hover{
    color: #aaa;
}

.red_bold{
    color:#F00;
    font-weight: bold;
    font-size:1.2em;
}
</style>